import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../config/constants';
import jwt_decode from 'jwt-decode';
import { history } from '../../history'
import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    USER_LOADED,
    USER_LOADING,
} from './types';
import isJwtExpired from '../../utils/jwtCheck';

// Check token and load user
export const loadUser = (dontRedirect) => async (dispatch) => {
    dispatch({ type: USER_LOADING });

    try {
        const token = localStorage.getItem('token');
        console.log(isJwtExpired(token))
        if (!token || isJwtExpired(token)) {
            dispatch(logout())
            dispatch({ type: AUTH_ERROR });
            return;
        }

        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // const res = await axios.get(`${BASE_URL}/user/`); // Replace with your API endpoint to fetch the authenticated user
        // if (data) {
        dispatch({ type: LOGIN_SUCCESS, payload: { token } });

        dispatch({ type: USER_LOADED, payload: true });
        // history.push('/dashboard')
        // }

    } catch (err) {
        dispatch({ type: AUTH_ERROR });
    }
};

export const loadUserWithoutLoading = () => async (dispatch) => {


    try {
        const token = localStorage.getItem('token');

        if (!token) {

            dispatch({ type: AUTH_ERROR });
            return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const res = await axios.get(`${BASE_URL}/user/`); // Replace with your API endpoint to fetch the authenticated user
        if (res.data) {
            dispatch({ type: USER_LOADED, payload: res.data });

        }

    } catch (err) {
        dispatch({ type: AUTH_ERROR });
    }
};

// Register user
export const register = ({ name, email, password }) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ name, email, password });

    try {
        const res = await axios.post(`${BASE_URL}/register`, body, config); // Replace with your API endpoint for user registration
        dispatch({ type: REGISTER_SUCCESS, payload: res.data });
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }

        dispatch({ type: REGISTER_FAIL });
    }
};

export const logoutUser = () => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const token = localStorage.getItem('refresh_token');

    const body = JSON.stringify({ refresh_token: token });

    try {
        const res = await axios.post(`${BASE_URL}/logout/`, body, config);
        dispatch(logout()) // Replace with your API endpoint for user registration
        return res.data

    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }


    }
};

export const forgotPassword = (data) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ ...data });

    try {
        const res = await axios.post(`${BASE_URL}/request_password_change/`, body, config);
        if (res.data.message) {
            toast.success(res.data.message)
        } else {
            toast.error(res.data.errors)

        }
        return res.data

    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }


    }
};


export const changePassword = (data) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ ...data });

    try {
        const res = await axios.post(`${BASE_URL}/change_password/`, body, config);
        if (res.data.message) {
            toast.success(res.data.message);
            return res.data
        } else {
            toast.error(res.data.errors)
        }

    } catch (err) {
        const errors = err.response.data.error;
        toast.error(errors)
    }
};

export const confirmResetPassword = (data, userId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const body = JSON.stringify({ ...data });
    try {
        const res = await axios.post(`${BASE_URL}/confirm_reset_password/${data.token}/`, body, config);
        if (res.data.message) {
            toast.success(res.data.message);
            return res.data
        } else {
            toast.error(res.data.errors)
        }

    } catch (err) {

        toast.error("Somthing went wrong while resetting password")
    }
};

export const resendConfirmationPassword = (data) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const body = JSON.stringify({ ...data });
    try {
        const res = await axios.post(`${BASE_URL}/resend_email_confirmation/`, body, config);
        if (res.data.message) {
            toast.success(res.data.message);
            return res.data
        } else {
            toast.error(res.data.errors)
        }

    } catch (err) {
        const errors = err.response.data.error;
        toast.error(errors)
    }
};

export const deleteUser = () => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    try {
        const res = await axios.delete(`${BASE_URL}/user/`, config);
        if (res.data.message) {
            dispatch(logoutUser())
            dispatch(logout())
            return res.data
        }

    } catch (err) {
        const errors = err.response.data.error;
        toast.error(errors)
    }
};

export const updateUserDetail = (data) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ ...data });

    try {
        const res = await axios.patch(`${BASE_URL}/user/`, body, config);
        if (res.data.message) {
            const message = res.data.message;
            dispatch(loadUserWithoutLoading())
            if (message) {
                message.forEach((message) => toast.info(message));
            }
            return res.data
        } else {
            toast.error(res.data.errors)
        }

    } catch (err) {
        const errors = err.response.data.error;
        toast.error(errors)
    }
};

// Login user
export const login = (data) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ ...data });

    try {
        axios.defaults.headers.common['Authorization'] = ``;

        const res = await axios.post(`${BASE_URL}/auth/admin/login/`, body, config); // Replace with your API endpoint for user login
        if (!res.data.errors) {
            dispatch({ type: LOGIN_SUCCESS, payload: res.data });

            dispatch(loadUser());
            return true

        } else {
            toast.error(res.data.errors)
            return res.data
        }
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }

        dispatch({ type: LOGIN_FAIL });
    }
};

// Logout user
export const logout = () => ({ type: LOGOUT });
