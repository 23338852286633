import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducers'; // Import your root reducer
import { history } from '../history';
// Create the Redux store
const store = createStore(rootReducer, applyMiddleware(thunk));

// Create the StoreProvider component
const StoreProvider = ({ children }) => {
    return (<Provider store={store}>{children}</Provider>)
};

export default StoreProvider;
