import { history } from './history.js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom"
import App from './App.jsx'
import './index.css'
import StoreProvider from './redux/store';
ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter history={history}>
    <StoreProvider>
      <App />
    </StoreProvider>
  </BrowserRouter>
  ,
)
