function isJwtExpired(token) {
	if (!token) {
		// Token is missing
		return true;
	}

	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	const payload = JSON.parse(jsonPayload);
	if (!payload.exp) {
		// Expiration time (exp) is missing in the token payload
		return true;
	}

	const expirationTime = new Date(payload.exp * 1000);
	const currentTime = new Date();

	return currentTime > expirationTime;
}


export default isJwtExpired;