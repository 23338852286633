

import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from "react-router-dom"
import './App.css'
import MainLayout from './layouts/Main';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './redux/actions/auth.actions';
import { CircularProgress } from '@mui/material';

const Login = lazy(() => import('./pages/Login'));
const Katalog = lazy(() => import('./pages/Katalog'));
const katalogDownloads = lazy(() => import('./pages/katalogDownloads'));
const Contact = lazy(() => import('./pages/Contact'));
const Vizualzacie = lazy(() => import('./pages/Vizualzacie'));
const VizualzacieImages = lazy(() => import('./pages/VizualzacieImages'));
const RealzacieImages = lazy(() => import('./pages/RealzacieImages'));

const Realzacie = lazy(() => import('./pages/Realzacie'));
const Videos = lazy(() => import('./pages/Video'));
const BlogCategories = lazy(() => import('./pages/BlogCategories'));
const Blogs = lazy(() => import('./pages/Blogs'));
const PageContent = lazy(() => import('./pages/PagesContent'));

const VizulacieCategories = lazy(() => import('./pages/VizulacieCategories'));
const RealzacieCategories = lazy(() => import('./pages/RealzacieCategories'));

const References = lazy(() => import('./pages/References'));

const HeaderMenu = lazy(() => import('./pages/HeaderMenu'));







function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Jakarta Sans, sans-serif',
    },
    palette: {
      primary: {
        main: '#ED1C24', 
        light: '#0da8db', 
        dark: '#08617f', // Dark shade
        contrastText: '#ffffff', 
      },
      secondary: {
        main: '#C3D046', 
        light: '#d9da6f', 
        dark: '#c7c934', // Dark shade
        contrastText: '#19053F', 
      },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: 'white', // Change the background color here
          },
        },
      },
    },
  });
  const dispatch = useDispatch()
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);



  React.useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token') {

        dispatch(loadUser());
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);



  if (loading) {
    
    return <div className='flex justify-center items-center h-screen'>
      <CircularProgress size={48} sx={{ color: '#0A7EA4' }} />
    </div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {isAuthenticated ? (
          <Route path='/' element={<MainLayout />}>
            <Route index element={<Navigate to='/dashboard' />} />
            <Route path='dashboard/katalog' element={renderLazyComponent(Katalog)} />
            <Route path='dashboard/katalog-downloads' element={renderLazyComponent(katalogDownloads)} />

            <Route path='dashboard/contact' element={renderLazyComponent(Contact)} />
            <Route path='dashboard/vizualzacie' element={renderLazyComponent(Vizualzacie)} />
            <Route path='dashboard/vizulacie-categories' element={renderLazyComponent(VizulacieCategories)} />
            <Route path='dashboard/vizualzacie/:id' element={renderLazyComponent(VizualzacieImages)} />

            <Route path='dashboard/realzacie/:id' element={renderLazyComponent(RealzacieImages)} />

            <Route path='dashboard/realzacie' element={renderLazyComponent(Realzacie)} />
            <Route path='dashboard/realzacie-categories' element={renderLazyComponent(RealzacieCategories)} />

            <Route path='dashboard/videos' element={renderLazyComponent(Videos)} />
            <Route path='dashboard/blog-categories' element={renderLazyComponent(BlogCategories)} />
            <Route path='dashboard/blogs' element={renderLazyComponent(Blogs)} />
            <Route path='dashboard/pages-content' element={renderLazyComponent(PageContent)} />

            <Route path='dashboard/references' element={renderLazyComponent(References)} />

            <Route path='dashboard/header-menu' element={renderLazyComponent(HeaderMenu)} />






            {/* <Route path="dashboard/team/:teamId" element={<ViewTeam />} /> */}
            <Route path='*' element={<Navigate to='/dashboard/katalog' />} />
          </Route>
        ) : (
          <>
            <Route path='/login' element={renderLazyComponent(Login)} />
            <Route path='*' element={<Navigate to='/login' />} />
          </>
        )}
      </Routes>
      <ToastContainer />
    </ThemeProvider>
  );
}

function renderLazyComponent(Component) {
  return (
    <Suspense fallback={<CircularProgress size={48} sx={{ color: '#0A7EA4' }} />}>
      <Component />
    </Suspense>
  );
}

export default App;
